import React, { ChangeEvent, useCallback } from "react";
import Header from "components/header";
import styles from "./maker-info.module.scss";
import buttonStyles from "styles/button.module.scss";
import cn from "classnames";
import useStickyState from "hooks/useStickyState";
import { useParams } from "react-router-dom";

type VideoInfo = {
  title: string;
  caption: boolean;
};

const defaultVideoInfo = {
  title: "",
  caption: false,
};

const videoInfoKey = "video_info";

export default function MakerInfo() {
  const { url } = useParams<{ url?: string }>();
  const [videoInfo, setVideoInfo] = useStickyState<VideoInfo>(
    defaultVideoInfo,
    decodeURIComponent(url || "") || videoInfoKey
  );

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      let { name, value } = ev.target;
      setVideoInfo((prev) => ({
        ...prev,
        [name]: name === "caption" ? !prev.caption : value,
      }));
    },
    [setVideoInfo]
  );

  return (
    <>
      <Header title="DESCRIBE GIF"></Header>
      <div className="content">
        <form className={styles.form}>
          <div className={styles.formItem}>
            <label htmlFor="title" className={styles.label}>
              Edit title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className={styles.input}
              value={videoInfo.title}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formItem}>
            <label htmlFor="caption" className={styles.label}>
              Caption
            </label>
            <div className={styles.checkboxGroup}>
              <input
                type="radio"
                name="caption"
                id="caption-off"
                value="off"
                checked={!videoInfo.caption}
                onChange={handleChange}
              />
              <label htmlFor="caption-off" className={cn(buttonStyles.btn, styles.checkboxLabel)}>
                OFF
              </label>
              <input
                type="radio"
                name="caption"
                value="on"
                id="caption-on"
                checked={videoInfo.caption}
                onChange={handleChange}
              />
              <label htmlFor="caption-on" className={cn(buttonStyles.btn, styles.checkboxLabel)}>
                CAPTION
              </label>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
