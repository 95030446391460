import React, { useState } from "react";
import Header from "components/header";
import buttonStyles from "styles/button.module.scss";
import styles from "./home.module.scss";
import cn from "classnames";
import { useHistory } from "react-router-dom";

export default function Home() {
  const [url, setUrl] = useState("");
  const history = useHistory();
  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    history.push(`/make/${encodeURIComponent(url)}`);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setUrl(value);
  };

  return (
    <div>
      <Header title="GIF MAKER - SHARE MOST FAPABLE SCENES WITH COMMUNITY"></Header>
      <div className="content">
        <div className={styles.hero}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              className={styles.input}
              type="text"
              placeholder="Link to tube video page"
              value={url}
              onChange={handleChange}
            />
            <button className={cn(buttonStyles.primary, styles.formButton)} type="submit">
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
