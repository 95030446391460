import { useEffect, useRef, SetStateAction, Dispatch, MutableRefObject } from "react";
import useStickyState from "./useStickyState";

export default function useStateRef<T>(
  initialValue: T | (() => T),
  key?: string
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [state, setState] = useStickyState(initialValue, key);
  const ref = useRef<T>(state);
  useEffect(() => {
    ref.current = state;
  }, [state]);

  return [state, setState, ref];
}
