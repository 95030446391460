import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./maker.module.scss";
import buttonStyles from "styles/button.module.scss";
import useStateRef from "hooks/useStateRef";
import useMounted from "hooks/useMounted";
import cn from "classnames";
import Header from "components/header";
import { HANDLE_DURATION, MAX_DURATION, MIN_DURATION } from "utils/constants";
import { useHistory, useParams } from "react-router-dom";
import {
  after,
  getOffsetX,
  getElementOffset,
  getX,
  addListener,
  removeListener,
  formatSeconds,
  debounce,
} from "utils";

type PlayerState = {
  isPlaying: boolean;
  currentTime: number;
  activeControls: boolean;
  showPoster: boolean;
  fromTime: number;
  delta: number;
  duration: number;
  offset: number;
};

const defaultPlayerState: PlayerState = {
  isPlaying: false,
  currentTime: 0,
  activeControls: true,
  showPoster: true,
  fromTime: 10,
  delta: 0,
  duration: 3,
  offset: 0,
};

const playerStateKey = "player_state";

export default function Maker() {
  const timelineHandleRef = useRef<HTMLDivElement>(null!);
  const timelineRef = useRef<HTMLDivElement>(null!);
  const videoRef = useRef<HTMLVideoElement>(null!);
  const framesRef = useRef<HTMLDivElement>(null!);
  const captureContainerRef = useRef<HTMLDivElement>(null!);
  const captureBarRef = useRef<HTMLDivElement>(null!);
  const captureRightRef = useRef<HTMLDivElement>(null!);
  const captureLeftRef = useRef<HTMLDivElement>(null!);
  const timelineHoverRef = useRef<HTMLSpanElement>(null!);
  const posterRef = useRef<HTMLCanvasElement>(null!);
  const timerRef = useRef<NodeJS.Timeout>();
  const [timelineHover, setTimelineHover] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [percentages, setPercentages] = useState({
    hover: 0,
    timelineProgress: 0,
    timelineHandle: 0,
    captureProgress: 0,
    captureBoxLeft: 0,
    captureBox: 0,
  });

  const history = useHistory();
  const params = useParams<{ url?: string }>();
  const isMounted = useMounted();

  const [playerState, setPlayerState, playerStateRef] = useStateRef<PlayerState>(
    defaultPlayerState,
    decodeURIComponent(params.url || "") || playerStateKey
  );

  const createPoster = useCallback(() => {
    const canvas = posterRef.current;
    const video = videoRef.current;
    const ctx = canvas.getContext("2d");
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }, []);

  const pause = useCallback((): Promise<void> => {
    const video = videoRef.current;
    setPlayerState((prev) => ({ ...prev, isPlaying: false }));
    return new Promise((resolve) => {
      after(video.pause(), () => {
        const onSeek = () => {
          video.removeEventListener("seeked", onSeek);
          createPoster();
          setPlayerState((prev) => ({
            ...prev,
            showPoster: true,
            currentTime: prev.fromTime + prev.offset,
          }));
          resolve();
        };

        const { fromTime, offset } = playerStateRef.current;
        video.currentTime = fromTime + offset;
        video.addEventListener("seeked", onSeek);
      });
    });
  }, [createPoster, setPlayerState, playerStateRef]);

  const play = useCallback(
    (playOnce?: boolean) => {
      let loopCount = 0;
      const video = videoRef.current;
      if (timerRef.current) clearInterval(timerRef.current);
      after(video.play(), () => {
        setPlayerState((prev) => ({
          ...prev,
          isPlaying: true,
          showPoster: false,
        }));

        const tick = () => {
          const { fromTime, offset, duration } = playerStateRef.current;
          if (timerRef.current && playOnce && loopCount === 1) {
            pause().then(() => (video.currentTime = fromTime + offset));
            return clearInterval(timerRef.current);
          }
          if (
            video.currentTime > fromTime + offset + duration ||
            video.currentTime < fromTime + offset - 0.01
          ) {
            video.currentTime = fromTime + offset;
            loopCount++;
          }

          setPlayerState((prev) => ({ ...prev, currentTime: video.currentTime }));
        };

        timerRef.current = setInterval(tick, 1000 / 60);
      });
    },
    [playerStateRef, setPlayerState, pause]
  );

  const captureFrames = useCallback(
    (latestContainer?: HTMLDivElement) => {
      const video = videoRef.current;
      const container = latestContainer || framesRef.current;
      const w = container.clientWidth;
      const h = container.clientHeight;
      const vw = video.videoWidth;
      const vh = video.videoHeight;

      const count = Math.ceil((w / h / vw) * vh);

      const frameDuration = HANDLE_DURATION / count;
      const frameWidth = Math.floor(w / count) - 1;
      const frameHeight = Math.floor((frameWidth * vh) / vw);
      const frameMargin = (w - frameWidth * count) / (count - 1);
      const framesHash = `${playerStateRef.current.fromTime}_${count}_${frameWidth}_${frameMargin}`;

      const hash = container.getAttribute("hash");
      if (hash === framesHash) return Promise.resolve();

      const getFrame = (i: number): Promise<any> => {
        return new Promise((resolve) => {
          const onSeek = () => {
            video.addEventListener("seeked", onSeek);
            const frame = document.createElement("canvas");
            frame.width = frameWidth;
            frame.height = frameHeight;

            const ctx = frame.getContext("2d");
            ctx?.drawImage(video, 0, 0, frameWidth, frameHeight);
            resolve(frame);
          };

          const { fromTime } = playerStateRef.current;
          video.currentTime = fromTime + (i - 0.5) * frameDuration;
          video.addEventListener("seeked", onSeek);
        });
      };

      const addFrames = (start = 1): Promise<any> => {
        return getFrame(start).then((frame) => {
          container.append(frame);
          if (start < count) {
            frame.style.marginRight = frameMargin + "px";
            return addFrames(start + 1);
          } else return Promise.resolve();
        });
      };

      container.setAttribute("hash", framesHash);
      container.innerHTML = "";

      return addFrames().then(() => pause());
    },
    [pause, playerStateRef]
  );

  const recapFrames = useCallback(
    (latestContainer?: HTMLDivElement) => {
      setPlayerState((prev) => ({ ...prev, activeControls: false }));
      return pause().then(() =>
        captureFrames(latestContainer).then(() =>
          setPlayerState((prev) => ({ ...prev, activeControls: true }))
        )
      );
    },
    [captureFrames, pause, setPlayerState]
  );

  useEffect(() => {
    if (isMounted) recapFrames();
  }, [isMounted]);

  const applyChanges = useCallback(() => {
    setPlayerState((prev) => ({ ...prev, fromTime: prev.fromTime + prev.delta, delta: 0 }));
    recapFrames();
  }, [recapFrames, setPlayerState]);

  const handleTimelineHover = (ev: React.MouseEvent | React.TouchEvent) => {
    setIsHovering(true);
    const vDuration = videoRef.current.duration || 0;
    const timeline = timelineRef.current;
    const timelineHover = timelineHoverRef.current;
    const pos = getElementOffset(timeline);
    const x = getX(ev.nativeEvent);
    let time = ((x - timelineHover.offsetWidth / 2) / pos.width) * vDuration;
    if (time < 0) time = 0;
    if (time > vDuration) time = vDuration;
    setTimelineHover(time);
  };

  const handleTimeline = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      event.preventDefault();
      if (!playerState.activeControls) return;
      const timelineHandle = timelineHandleRef.current;
      const timeline = timelineRef.current;
      const handleOffset = event.target === timelineHandle ? getOffsetX(event.nativeEvent) : 0;

      let isDragging = true;
      const timelineEvent = (ev: MouseEvent | TouchEvent) => {
        const vDuration = videoRef.current.duration || 0;
        const max = vDuration - HANDLE_DURATION;
        const pos = getElementOffset(timeline);
        const x = getX(ev) - handleOffset;
        let time = ((x - pos.left) / pos.width) * vDuration;
        if (time < 0) time = 0;
        if (time > max) time = max;
        setPlayerState((prev) => ({ ...prev, delta: time - prev.fromTime }));
      };

      timelineEvent(event.nativeEvent);

      addListener(document, "mousemove touchmove", (e: MouseEvent | TouchEvent) => {
        if (isDragging && playerState.activeControls) timelineEvent(e);
      });

      addListener(document, "touchend mouseup", () => {
        if (isDragging) applyChanges();
        isDragging = false;
      });
    },
    [applyChanges, playerState, setPlayerState]
  );

  const handleCapture = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      event.preventDefault();
      const captureContainer = captureContainerRef.current;
      const captureLeft = captureLeftRef.current;
      const captureRight = captureRightRef.current;
      const captureBar = captureBarRef.current;
      const isBackground = event.target === captureContainer;
      const isRight = event.target === captureRight;
      const isLeft = event.target === captureLeft;
      const isBar = event.target === captureBar;
      const shift = !isBackground ? getOffsetX(event.nativeEvent) : 0;

      const captureEvent = (ev: MouseEvent | TouchEvent) => {
        const pos = getElementOffset(captureContainer);
        const x = getX(ev);
        let offset = playerState.offset;
        let duration = playerState.duration;

        if (isBackground || isBar) {
          offset = ((x - shift - pos.left) / pos.width) * HANDLE_DURATION;
        }

        if (isRight) {
          duration = ((x - pos.left + 25 - shift) / pos.width) * HANDLE_DURATION - offset;
          if (duration > MAX_DURATION) offset += duration - MAX_DURATION;
          if (duration < MIN_DURATION) offset -= MIN_DURATION - duration;
        }

        if (isLeft) {
          const rightPos = getElementOffset(captureRight);
          offset = ((x - pos.left) / pos.width) * HANDLE_DURATION;
          duration = ((rightPos.left - pos.left + 25) / pos.width) * HANDLE_DURATION - offset;
        }

        if (duration < MIN_DURATION) duration = MIN_DURATION;
        if (duration > MAX_DURATION) duration = MAX_DURATION;

        if (offset + duration > HANDLE_DURATION) offset = HANDLE_DURATION - duration;
        if (offset < 0) offset = 0;

        setPlayerState((prev) => ({ ...prev, duration, offset }));
      };

      isBackground && captureEvent(event.nativeEvent);
      addListener(document, "mousemove touchmove", captureEvent);
      addListener(document, "mouseup touchend", () => {
        removeListener(document, "mousemove touchmove", captureEvent);
      });
    },
    [playerState, setPlayerState]
  );

  useEffect(() => {
    const vDuration = videoRef.current.duration || 0;
    const hover = (timelineHover * 100) / vDuration;
    const timelineProgress = ((playerState.fromTime + playerState.delta) / vDuration) * 100;
    const timelineHandle = (100 * HANDLE_DURATION) / vDuration;
    const captureProgress = playerState.isPlaying
      ? (100 * (playerState.currentTime - playerState.fromTime - playerState.offset)) /
        playerState.duration
      : 0;

    const captureBoxLeft = (100 * playerState.offset) / HANDLE_DURATION;
    const captureBox = (100 * playerState.duration) / HANDLE_DURATION;
    setPercentages({
      hover,
      timelineProgress,
      timelineHandle,
      captureProgress,
      captureBoxLeft,
      captureBox,
    });
  }, [timelineHover, playerState]);

  const handleResize = useCallback(() => {
    const container = framesRef.current;
    if (playerState.activeControls) {
      recapFrames(container);
    }
  }, [recapFrames, playerState]);

  const debouncedResize = debounce(handleResize);

  const next = useCallback(() => {
    history.push(`/describe/${params.url}`);
  }, [history, params]);

  useEffect(() => {
    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, [debouncedResize]);

  return (
    <>
      <Header title="CREATE NEW GIF" />
      <div className={styles.container}>
        <section className={styles.top}>
          <div className={styles.videoContainer}>
            <video
              src={decodeURIComponent(params.url || "")}
              playsInline
              className={styles.video}
              ref={videoRef}
            ></video>
            <canvas
              className={cn(styles.poster, { [styles.posterActive]: !playerState.isPlaying })}
              width="1280"
              height="720"
              ref={posterRef}
            ></canvas>
          </div>
          <div
            className={cn(styles.controls, { [styles.controlsActive]: playerState.activeControls })}
          >
            <button onClick={() => play(true)} className={cn(buttonStyles.btn, styles.control)}>
              Play
            </button>
            <button onClick={() => play()} className={cn(buttonStyles.btn, styles.control)}>
              Demo
            </button>
            <button onClick={() => pause()} className={cn(buttonStyles.btn, styles.control)}>
              Stop
            </button>
            <button onClick={() => next()} className={cn(buttonStyles.primary, styles.control)}>
              Next
            </button>
          </div>
        </section>
        <section className={styles.bottom}>
          <div
            className={styles.timeline}
            onMouseDown={handleTimeline}
            onTouchStart={handleTimeline}
            onMouseMove={handleTimelineHover}
            onMouseLeave={() => setIsHovering(false)}
            ref={timelineRef}
          >
            <span
              className={styles.timelineHover}
              style={{
                left: percentages.hover + "%",
                display: isHovering ? "block" : "none",
              }}
              ref={timelineHoverRef}
            >
              {formatSeconds(timelineHover)}
            </span>
            <div
              className={styles.timelineProgress}
              style={{
                width: percentages.timelineProgress + "%",
              }}
            ></div>
            <div
              className={styles.timelineHandle}
              ref={timelineHandleRef}
              style={{
                width: percentages.timelineHandle + "%",
              }}
            ></div>
          </div>
          <div className={styles.capture} ref={captureContainerRef} onMouseDown={handleCapture}>
            <div className={styles.frames} ref={framesRef}></div>
            <div
              className={styles.captureBox}
              style={{
                width: percentages.captureBox + "%",
                left: percentages.captureBoxLeft + "%",
              }}
              ref={captureBarRef}
            >
              <div
                className={styles.captureProgress}
                style={{
                  width: percentages.captureProgress + "%",
                }}
              ></div>
              <div
                className={styles.captureLeft}
                ref={captureLeftRef}
                onMouseDown={handleCapture}
                onTouchStart={handleCapture}
              ></div>
              <div
                className={styles.captureRight}
                ref={captureRightRef}
                onMouseDown={handleCapture}
                onTouchStart={handleCapture}
              ></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
