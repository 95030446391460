import { useState, useEffect, Dispatch, SetStateAction } from "react";

export default function useStickyState<T>(
  defaultValue: T | (() => T),
  key?: string,
  debounceMs: number = 500
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      // Creating variable for this check does not work. Following will not work
      // e.g  `const callable = defaultValue instanceof Function`
      //      `return callable ? defaultValue() : defaultValue`  -> TypeScript error
      return defaultValue instanceof Function ? defaultValue() : defaultValue;
    }

    const stickyValue = window.localStorage.getItem(key || "");
    if (stickyValue !== null) return JSON.parse(stickyValue);

    return defaultValue instanceof Function ? defaultValue() : defaultValue;
  });

  useEffect(() => {
    if (typeof window === "undefined" || !key) return;

    const timerId = setTimeout(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, debounceMs);

    return () => clearTimeout(timerId);
  }, [key, value, debounceMs]);

  return [value, setValue];
}
