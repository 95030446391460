import React from "react";
import styles from "./header.module.scss";

type IProps = {
  title: string;
};

export default function Header({ title }: IProps) {
  return (
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
}
