export const after = (p: Promise<any> | any, f: any) => (p ? p.then(f) : f());
export const addListener = (element: any, events: string, listener: any) => {
  events.split(" ").map((ev) => element.addEventListener(ev, listener));
};

export const removeListener = (element: any, events: string, listener: any) => {
  events.split(" ").map((ev) => element.removeEventListener(ev, listener));
};

export const formatSeconds = (seconds: number) => {
  return Math.floor(seconds / 60) + ":" + ("0" + Math.floor(seconds % 60)).slice(-2);
};

export const getElementOffset = (element: HTMLElement) => {
  const doc = document.documentElement;
  const box = element.getBoundingClientRect();
  const top = box.top + window.pageYOffset - doc.clientTop;
  const left = box.left + window.pageXOffset - doc.clientLeft;
  return {
    left,
    top,
    width: element.clientWidth,
    height: element.clientHeight,
  };
};

export const getX = (event: MouseEvent | TouchEvent) => {
  if ("touches" in event) {
    return event.touches[0].clientX;
  }
  return event.clientX;
};

export const getOffsetX = (event: MouseEvent | TouchEvent) => {
  return getX(event) - getElementOffset(event.target as HTMLElement).left;
};

export function debounce<Params extends any[]>(
  fn: (...args: Params) => any,
  ms: number = 100
): (...args: Params) => void {
  let timer: NodeJS.Timeout;

  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, ms);
  };
}

export { saveInfoLocal, getInfoLocal } from "./local-storage";
