import React from "react";
import Maker from "components/maker";
import MakerInfo from "components/makerInfo";
import Home from "components/home";
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/make/:url">
          <Maker />
        </Route>
        <Route path="/describe/:url">
          <MakerInfo />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
